import React, {useEffect, useState} from "react";
import {Title} from "ui/base/Title";
import {
    Col,
    ResourcesViewStyled,
    Row,
    WebsitesWrapper,
} from "./ResourcesViewStyled";
import {Table} from "ui/base/Table";
import {ResourcesPeoples} from "ui/blocks/resources/ResourcesPeoples";
import api from 'api';
import FacilityInspectionTable from "ui/blocks/resources/FacilityInspectionTable/FacilityInspectionTable";
import {WDID} from "mock/WDID";
import store from "ui/redux/store";


const ResourcesView = () => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const [facRes, setFacRes] = useState([]);
    const [newWDID, setNewWDID] = useState(WDID)
    store.subscribe(() => setNewWDID(store.getState().wdid))
    const [facilities, setFacilities] = useState()

    useEffect(()=>{
        api.get('facilities').then(
            (res) => {
                let fID = res.data.find((d: any) => d.wdid === newWDID)
                setFacilities(fID?.id)
            }
        )
    })


    useEffect(() => {
        if(facilities) {
            api.getSome('facilities2resources', `?facility_id=eq.${facilities}`)
                .then((res: any) => {
                    setFacRes(res.data)

                    api.get('resources')
                        .then(resu => resu)
                        .then(
                            (result) => {
                                setItems(result.data);
                                setIsLoaded(true)
                            },
                            (error) => {
                                setError(error.error);
                            }
                        )
                }).then((error: any) => {
                setError(error)
            })
        }
    }, [facilities])
    if (error) {
        return <div>Error: {error}</div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {
        const facility_doc = items.filter((arr: any) => arr.r_type === "facility_doc")
        const website = items.filter((arr: any) => arr.r_type === "website")

        const newFacDocs:any = []

        facRes.forEach(({resource_id})=>{
            return newFacDocs.push(facility_doc.filter((val:any)=>val.id === resource_id)[0])
        })
        return (
            <ResourcesViewStyled>
                <Row>
                    <Col>
                        <Title size="h5" position="left">
                            Documents
                        </Title>
                        <Table
                            width={335}
                            header={
                                <tr>
                                    <th>Document</th>
                                    <th>Document Type</th>
                                    <th>Download</th>
                                </tr>
                            }
                        >
                            {newFacDocs.map((item: any) => (
                                <tr>
                                    <td align="center">{!!item?.title ? item.title : '-'}</td>
                                    <td align="center">{
                                        item?.doc_type === 'swppp' ? 'Stormwater Pollution Prevention Plan (SWPPP)'
                                            : item?.doc_type === 'site_map' ? 'Site Map'
                                                : item?.doc_type === 'lab_reports' ? 'Lab Reports'
                                                    : item?.doc_type === 'coc' ? 'Chain of custody (COC)'
                                                        : item?.doc_type === 'group_leader_inspection_notes' ? 'Group Leader Inspection Notes'
                                                            : item?.doc_type === 'era_reports' ? 'Exceedance Response Action (ERA) Report'
                                                                : item?.doc_type === 'visual_observation_forms' ? 'Visual Observation Forms'
                                                                    : item?.doc_type === 'training_docs' ? 'Training Document' : '-'
                                    }</td>
                                    <td align="center">
                                        <a href={item?.link}>Link</a>
                                    </td>
                                </tr>
                            ))}
                        </Table>
                    </Col>
                    <Col>
                        <Title size="h5" position="left">
                            Forms
                        </Title>
                        <FacilityInspectionTable/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {/*<Title size="h5" position="left">*/}
                        {/*    Training Documents*/}
                        {/*</Title>*/}
                        {/*<Table*/}
                        {/*    width={335}*/}
                        {/*    header={*/}
                        {/*        <tr>*/}
                        {/*            <th>Document</th>*/}
                        {/*            <th>Download</th>*/}
                        {/*        </tr>*/}
                        {/*    }*/}
                        {/*>*/}
                        {/*    {training_doc.map((item: any) => (*/}
                        {/*        <tr>*/}
                        {/*            <td align="center">{item.title}</td>*/}
                        {/*            <td align="center">*/}
                        {/*                <a href={item.link}>Link</a>*/}
                        {/*            </td>*/}
                        {/*        </tr>*/}
                        {/*    ))}*/}
                        {/*</Table>*/}
                    </Col>
                    <Col>
                        <Title size="h5" position="left">
                            Useful Websites
                        </Title>
                        <WebsitesWrapper>
                            <ul>
                                {website.map((item: any) => (<li>
                                    <a href={item.link}>
                                        {item.description}
                                    </a>
                                </li>))}
                            </ul>
                        </WebsitesWrapper>
                    </Col>
                </Row>

                    <Title size="h5" position="left">
                        Meet Our Stormwater Team
                    </Title>
                    <ResourcesPeoples/>

            </ResourcesViewStyled>
        );
    }
};

export default ResourcesView;
